import { type ReactNode, useReducer } from 'react';

import { OrderModalActionsContext, OrderModalContext } from './OrderActionModals.Context';
import { orderActionsModalsReducer } from './Reducer';
import { OrderActionModalsState } from '../../types';

export const initialOrderModalRoot: OrderActionModalsState = {
  data: {},
  customerAddressIsOpen: false,
  undoRTOIsOpen: false,
  orderDetailsIsOpen: false,
  editOrderIsOpen: false,
  markAsRTOIsOpen: false,
  receiveOrderIsOpen: false,
  editPaymentIsOpen: false,
  editCustomerIsOpen: false,
  editDispatchZoneIsOpen: false,
  forceActionsIsOpen: false,
  cancelOrderIsOpen: false,
  addressHistoryIsOpen: false,
  failReturnIsOpen: false,
  internationalShipmentIsOpen: false,
  internationalErrorsIsOpen: false,
  proceduresIsOpen: false,
};

export function OrderModalProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(orderActionsModalsReducer, initialOrderModalRoot);

  return (
    <OrderModalActionsContext.Provider value={dispatch}>
      <OrderModalContext.Provider value={state}>{children} </OrderModalContext.Provider>
    </OrderModalActionsContext.Provider>
  );
}
