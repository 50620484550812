import { gql } from '@apollo/client';

export const GET_DROP_PROFILES = gql`
  query GetDropProfiles($retailerId: UUID!) {
    dropProfiles(retailerId: $retailerId, orderBy: "createdAt") {
      id
      name
      key
      description
      profileType
      cutoffTime
      billingSlab
      isDefault
      isPrimary
      isActive
      createdAt
      cities {
        city
        cutoffTime
        billingSlab
      }
      typeDetails {
        ... on StandardProfileDto {
          __typename
          endOfDayTime
          extraDays
        }

        ... on ExpressProfileDto {
          __typename
          drivingTimeInMins
          maximumEndOffsetInMins
        }

        ... on CustomProfileDto {
          __typename
          leadTimeInHours
          timeSlotsDurationInDays
          timeSlots {
            from
            to
          }
          preScheduleSlot {
            from
            to
          }
        }
      }
    }
  }
`;

export const GET_SIMPLE_DROP_PROFILES = gql`
  query GetDropProfiles($retailerId: UUID!, $isActive: Boolean) {
    dropProfiles(retailerId: $retailerId, isActive: $isActive) {
      id
      profileType
      name
      key
      isActive
      isDefault
      typeDetails {
        ... on CustomProfileDto {
          timeSlots {
            from
            to
          }
        }
      }
    }
  }
`;

export const CREATE_DROP_PROFILE = gql`
  mutation CreateDropProfile($createDropProfileInput: CreateDropProfileInput!) {
    createDropProfile(createDropProfileInput: $createDropProfileInput) {
      message
      data {
        id
      }
    }
  }
`;

export const UPDATE_DROP_PROFILE = gql`
  mutation UpdateDropProfile($updateDropProfileInput: UpdateDropProfileInput!) {
    updateDropProfile(updateDropProfileInput: $updateDropProfileInput) {
      message
      data {
        id
      }
    }
  }
`;

export const REMOVE_DROP_PROFILE = gql`
  mutation RemoveDropProfile($id: UUID!) {
    removeDropProfile(id: $id) {
      message
      success
    }
  }
`;

export const MARK_AS_PRIMARY_QUERY = gql`
  mutation MarkAsPrimary($markPrimaryInput: MarkPrimaryInput!) {
    markAsPrimary(markPrimaryInput: $markPrimaryInput) {
      message
    }
  }
`;
export const TOGGLE_PROFILE_STATUS_QUERY = gql`
  mutation UpdateDropProfile($updateDropProfileInput: UpdateDropProfileInput!) {
    updateDropProfile(updateDropProfileInput: $updateDropProfileInput) {
      message
    }
  }
`;
