// Main Tabs Enum
export enum MainTabsEnum {
  AccountConfig = 'ACCOUNT_CONFIGURATION',
  PickupAndDrop = 'PICKUP_AND_DROP',
  OrderFeatureAndFees = 'ORDER_FEATURES_AND_FEES',
  LegalAndBilling = 'LEGAL_AND_BILLING',
}

// Subtabs Enums
export enum AccountConfigSubtabsEnum {
  AccountSettings = 'ACCOUNT_SETTINGS',
  EmailSettings = 'EMAIL_SETTINGS',
  NotificationTriggers = 'NOTIFICATION_TRIGGERS',
  InventorySettings = 'INVENTORY_SETTINGS',
  SopsSettings = 'SOPS_SETTINGS',
  storeFoxIntegration = 'STOREFOX_INTEGRATION',
}

export enum PickupAndDropSubtabsEnum {
  PickupSettings = 'PICKUP_SETTINGS',
  DropSettings = 'DROP_SETTINGS',
}

export enum OrderFeatureAndFeesSubtabsEnum {
  OrderFeatures = 'ORDER_FEATURES',
  BillingSlabsSettings = 'BILLING_SLABS_SETTINGS',
}

export enum LegalAndBillingSubtabsEnum {
  LegalInformation = 'LEGAL_INFORMATION',
  BillingInformation = 'BILLING_INFORMATION',
  SubscriptionSettings = 'SUBSCRIPTION_SETTINGS',
}
export type AllSubtabsEnum =
  | AccountConfigSubtabsEnum
  | PickupAndDropSubtabsEnum
  | OrderFeatureAndFeesSubtabsEnum
  | LegalAndBillingSubtabsEnum;
// Type Definitions
type Tab = { name: string; id: string };
type SubtabMapping = Record<MainTabsEnum, Tab[]>;

// Main Tabs Array
export const mainTabs: Tab[] = [
  { name: 'Account Configuration', id: MainTabsEnum.AccountConfig },
  { name: 'Pickup and Drop Scheduling', id: MainTabsEnum.PickupAndDrop },
  { name: 'Order Features and Fees', id: MainTabsEnum.OrderFeatureAndFees },
  { name: 'Legal and Billing Details', id: MainTabsEnum.LegalAndBilling },
];

// Subtabs Arrays
export const accountConfigSubtabs: Tab[] = [
  { name: 'Account Settings', id: AccountConfigSubtabsEnum.AccountSettings },

  { name: 'Auto Email Updates', id: AccountConfigSubtabsEnum.EmailSettings },
  { name: 'Customer Notifications', id: AccountConfigSubtabsEnum.NotificationTriggers },
  { name: 'Inventory Settings', id: AccountConfigSubtabsEnum.InventorySettings },
  // { name: 'StoreFox Integration', id: AccountConfigSubtabsEnum.storeFoxIntegration },

  { name: 'SOPs Settings', id: AccountConfigSubtabsEnum.SopsSettings },
];

export const pickupAndDropSubtabs: Tab[] = [
  { name: 'Pickup Settings', id: PickupAndDropSubtabsEnum.PickupSettings },
  { name: 'Drop Settings', id: PickupAndDropSubtabsEnum.DropSettings },
];

export const orderFeatureAndFeesSubtabs: Tab[] = [
  { name: 'Order Features', id: OrderFeatureAndFeesSubtabsEnum.OrderFeatures },
  // { name: 'Billing Slabs Settings', id: OrderFeatureAndFeesSubtabsEnum.BillingSlabsSettings },
];

export const legalAndBillingSubtabs: Tab[] = [
  { name: 'Legal Information', id: LegalAndBillingSubtabsEnum.LegalInformation },
  { name: 'Billing Information', id: LegalAndBillingSubtabsEnum.BillingInformation },
  { name: 'Subscription Settings', id: LegalAndBillingSubtabsEnum.SubscriptionSettings },
];

// Mapping Main Tabs to Subtabs
export const subtabMapping: SubtabMapping = {
  [MainTabsEnum.AccountConfig]: accountConfigSubtabs,
  [MainTabsEnum.PickupAndDrop]: pickupAndDropSubtabs,
  [MainTabsEnum.OrderFeatureAndFees]: orderFeatureAndFeesSubtabs,
  [MainTabsEnum.LegalAndBilling]: legalAndBillingSubtabs,
};

export const isEqual = (a: string | null | undefined, b: string | null | undefined) =>
  a === b || (a == null && b === '') || (a === '' && b == null);
export function containsMainTab(value: string): boolean {
  const mainTabValues = Object.values(MainTabsEnum);
  return mainTabValues.includes(value);
}
