import { gql } from '@apollo/client';

export const GET_ZONES_QUERY = gql`
  query Zones {
    zones {
      name
      id
      abbreviation
    }
  }
`;
