import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Fade,
  FormLabel,
  HStack,
  SBEditIcon,
  SBInput,
  SBModal,
  SBTextArea,
  Stack,
  Switch,
  Text,
  toast,
} from '@swftbox/style-guide';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  CommentType,
  type Order,
  useOrderModalActions,
  useOrderModalContext,
  useUpdateOrder,
} from 'src/components/Particles';
import { UpdateOrderSchema } from './order.schema';
import AddCommentModal from '../Comment/AddCommentModa';

interface UpdateOrderForm {
  notes?: string;
  requireDeliveryNote: boolean;
  packageCount: number;
  isInternational?: boolean;
  b2bHandling?: boolean;
  oversized?: boolean;
}

export function EditOrderModal() {
  const { editOrderIsOpen, data: order } = useOrderModalContext() as {
    editOrderIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UpdateOrderForm>({
    mode: 'all',
    resolver: yupResolver(UpdateOrderSchema),
    defaultValues: { ...order },
  });

  const { updateOrder } = useUpdateOrder();
  const b2bHandling = watch('b2bHandling');
  const oversized = watch('oversized');

  useEffect(() => {
    if (b2bHandling) {
      setValue('requireDeliveryNote', true);
    }
  }, [b2bHandling, setValue]);

  useEffect(() => {
    reset({
      notes: order.notes,
      requireDeliveryNote: order.requireDeliveryNote,
      packageCount: order.packageCount,
      isInternational: order.isInternational,
      b2bHandling: order.b2bHandling,
      oversized: order.oversized,
    });
  }, [editOrderIsOpen, order, reset]);

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    dispatch({ type: 'CLOSE_EDIT_ORDER' });
  };

  function onConfirm(data: UpdateOrderForm) {
    void updateOrder({
      payload: {
        ...data,
        id: order.id,
        requireDeliveryNote: data.b2bHandling ?? data.requireDeliveryNote,
      },
      onCompleted: ({ updateOrder }) => {
        handleCloseModal(updateOrder.message);
      },
    });
  }
  return (
    <>
      <SBModal
        isOpen={editOrderIsOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Edit Order</Text>
          </Box>
        }
        size="xl"
        body={
          <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={2}>
            <SBInput
              crossOrigin={undefined}
              type="number"
              label="Package Count *"
              {...register('packageCount')}
              error={errors.packageCount?.message}
            />
            <SBTextArea
              label="Order remarks"
              {...register('notes')}
              error={errors.notes?.message}
            />

            <HStack spacing={6}>
              <FormLabel htmlFor="requireDeliveryNote">Require a delivery note?</FormLabel>
              <Switch id="requireDeliveryNote" {...register('requireDeliveryNote')} />
            </HStack>
            <HStack spacing={6}>
              <FormLabel htmlFor="isInternational">International Order?</FormLabel>
              <Switch id="isInternational" {...register('isInternational')} />
            </HStack>
            <HStack spacing={12}>
              <FormLabel htmlFor="b2bHandling">B2B Order?</FormLabel>
              <Switch id="b2bHandling" {...register('b2bHandling')} />
            </HStack>
            <HStack spacing={12}>
              <FormLabel htmlFor="Oversized">Oversized?</FormLabel>
              <Switch id="Oversized" {...register('oversized')} />
              {!!oversized && (
                <HStack mt={4}>
                  <Fade in={oversized} unmountOnExit>
                    <Box bg="#EFEFEF" borderRadius="4" w="max-content">
                      <HStack spacing="0">
                        <AddCommentModal
                          order={order}
                          type={CommentType.OVERSIZED}
                          buttonText={'Add Comment'}
                        />
                      </HStack>
                    </Box>
                  </Fade>
                </HStack>
              )}
            </HStack>
          </Stack>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
