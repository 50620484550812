import { openOrdersShippingLabels } from 'src/components/Pages/Orders/Utils/pdf.helper';
import { Config } from 'src/config';
import { Address, Order, OrderGroupType, OrderState } from '../resolvers';
import { OrdersStatusFilter } from 'src/components/Pages/Orders/order.types';

export const stateBadgeColor = (
  status: string,
  state: string,
  isReverse?: boolean
): { bg: string; color: string; text: string; border?: string } => {
  const stateColors: Record<string, { bg: string; color: string; text: string; border?: string }> =
    {
      draft: { bg: '#C2C2C2', color: '#fff', text: 'draft' },
      inFulfillment: { bg: '#63C3EC', color: '#fff', text: 'in Fulfillment' },
      requested: { bg: '#FFA012', color: '#fff', text: 'requested' },
      readyForPickup: { bg: '#FFA012', color: '#fff', text: 'Pickup Ready' },
      transitAssigned: { bg: '#F0CA00', color: '#fff', text: 'Transit Assigned' },
      inTransit: { bg: '#53B77C', color: '#fff', text: 'in Transit' },
      flagged: { bg: '#EB4D70', color: '#fff', text: 'flagged' },
      processing: { bg: '#FFA012', color: '#fff', text: 'processing' },
      ready: { bg: '#FFA012', color: '#fff', text: 'ready' },
      assigned: { bg: '#63C3EC', color: '#fff', text: 'assigned' },
      delivering: { bg: '#53B77C', color: '#fff', text: 'delivering' },
      pickedUp: { bg: '#53B77C', color: '#fff', text: 'delivering' },
      rtoAssigned: { bg: '#F0CA00', color: '#fff', text: 'rto Assigned' },
      failedAttempt: { bg: '#fff', color: '#EB4D70', text: 'failed Attempt', border: '#EB4D70' },
      rtoReady: { bg: '#FFA012', color: '#fff', text: 'rto Ready' },
      rto: { bg: '#FFA012', color: '#fff', text: 'rto' },
      rtoInTransit: { bg: '#53B77C', color: '#fff', text: 'rto transit' },
      delivered: { bg: '#fff', color: '#000000', text: 'delivered', border: '#000000' },
      rtoComplete: { bg: '#fff', color: '#626B7E', text: 'rto Complete', border: '#000000' },
      fulfillment: { bg: '#63C3EC', color: '#fff', text: 'fulfillment' },
      inJob: { bg: '#12B76A', color: '#fff', text: 'in Job' },
      warehouse: { bg: '#EAAA08', color: '#fff', text: 'warehouse' },
      cancelled: { bg: '#F04438', color: '#fff', text: 'cancelled' },
      // job status
      failed: { bg: '#fff', color: '#EB4D70', text: 'Failed', border: '#EB4D70' },
      pending: { bg: '#FFA012', color: '#fff', text: 'Pending' },
      notVisited: { bg: '#63C3EC', color: '#fff', text: 'Not Visited' },
      visited: { bg: '#12B76A', color: '#fff', text: 'Visited' },
      completed: { bg: '#fff', color: '#000000', text: 'Completed', border: '#000000' },
    };

  const returnStatusColor: Record<
    string,
    { bg: string; color: string; text: string; border?: string }
  > = {
    delivered: { bg: '#fff', color: '#626B7E', text: 'Return Complete', border: '#000000' },
    delivering: { bg: '#53B77C', color: '#fff', text: 'Returning to Partner' },
    pickedUp: { bg: '#53B77C', color: '#fff', text: 'Returning to Partner' },
    ready: { bg: '#FFA012', color: '#fff', text: 'Received (Return)' },
    inTransit: { bg: '#53B77C', color: '#fff', text: 'Picked Up (Return)' },
    failedAttempt: { ...stateColors.failedAttempt, text: 'Failed Attempt (Return)' },
    readyForPickup: { ...stateColors.ready, text: 'Ready (Return)' },
  };

  if (status === 'failedAttempt' && state === 'warehouse' && !isReverse) {
    return { bg: '#fff', color: '#EB4D70', text: 'Ready (Failed)', border: '#EB4D70' };
  }

  if (isReverse && !!returnStatusColor[status]) {
    return returnStatusColor[status];
  }

  return stateColors[status] || { bg: 'green', color: '#fff', text: status };
};

export const isDropDate = ({ isReverse, drop, pickup }: any) => {
  if (drop || pickup) {
    return isReverse ? new Date(drop) : new Date(pickup);
  }
  return new Date();
};

export const isCustomerConsumer = (order: any) =>
  order?.isReverse
    ? { consumer: order.retailer, location: order.from }
    : { consumer: order.customer, location: order.to };

export const goToPrintScreen = (order: Order) => {
  void openOrdersShippingLabels([order], undefined, order.retailer.shippingLabelSize);
};

export const openTrackingApp = (swftboxTracking: string) => {
  const url = `${Config.TRACKING_APP_URL}/${swftboxTracking}`;
  window.open(url, '_blank');
};

export const pendingSLALabel = (order: Order) =>
  order.state === OrderState.draft ? 'Pending Request' : 'Pending Pickup';

export const fixPrecision = (amount: number): string => {
  const precision = Config.PRIMEXPRESS_BUILD ? 3 : 2;
  return amount.toFixed(precision);
};

const isOtherExchangeCompleteOrder = (order: Order) => {
  return !!order.groupedOrders?.find(
    (groupedOrder) =>
      order.id !== groupedOrder.id &&
      [
        OrdersStatusFilter.CANCELLED,
        OrdersStatusFilter.DELIVERED,
        OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE,
        OrdersStatusFilter.RTO_COMPLETE,
      ].includes(groupedOrder.status as OrdersStatusFilter)
  )!;
};

export const exchangeOrdersFilter = (orders: Order[]) => {
  return orders.filter((order: Order) => {
    if (order.groupType !== OrderGroupType.EXCHANGE) {
      return true;
    }

    const isReverse = order.isReverse;
    const completedState = [
      OrdersStatusFilter.CANCELLED,
      OrdersStatusFilter.DELIVERED,
      OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE,
      OrdersStatusFilter.RTO_COMPLETE,
    ];
    const isOtherCompleteOrder = isOtherExchangeCompleteOrder(order);

    return (
      (isReverse &&
        !completedState.includes(order.status as OrdersStatusFilter) &&
        isOtherCompleteOrder) ||
      (!isReverse &&
        completedState.includes(order.status as OrdersStatusFilter) &&
        isOtherCompleteOrder) ||
      (!isReverse &&
        !completedState.includes(order.status as OrdersStatusFilter) &&
        !isOtherCompleteOrder)
    );
  });
};
export const getAddress = (location: Address) => {
  return [location.addressLine1, location.addressLine2, location.city, location.country]
    .filter((str) => !!str)
    .join(', ');
};
