import { type ComponentNode, renderComponent } from './RenderTermsJSON';
import { type DynamicFields, fillTermsContentWithDynamicFields } from './TermsContentHelper';

interface TermsContentProps {
  termsContent: string;
  dynamicFields: DynamicFields;
}

function TermsContent({ termsContent, dynamicFields }: TermsContentProps) {
  const termsContentWDynamicFields = fillTermsContentWithDynamicFields(termsContent, dynamicFields);

  const contentJson = JSON.parse(termsContentWDynamicFields) as ComponentNode;

  return <>{renderComponent(contentJson)}</>;
}

export default TermsContent;
