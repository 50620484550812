import { memo } from 'react';
import {
  CancelOrderModal,
  EditCityZoneModal,
  EditCustomerLocation,
  EditCustomerModal,
  EditOrderModal,
  EditOrderPaymentModal,
  ForceActionsOrderModal,
  MarkOrderAsRTO,
  OrderDetailsModal,
  ReceiveOrder,
  UndoRTO,
  FailReturn,
} from '../Modal';
import { AddressHistoryModal } from '../Modal/AddressHistory';
import { InternationalErrorsModal, InternationalShipment } from 'src/components/Organisms';

const CellModals = () => {
  return (
    <>
      <EditCustomerLocation />
      <OrderDetailsModal />
      <EditOrderModal />
      <FailReturn />
      <MarkOrderAsRTO />
      <UndoRTO />
      <ReceiveOrder />
      <EditOrderPaymentModal />
      <EditCustomerModal />
      <InternationalShipment />
      <CancelOrderModal />
      <ForceActionsOrderModal />
      <EditCityZoneModal />
      <AddressHistoryModal />
      <InternationalErrorsModal />
    </>
  );
};

export default memo(CellModals);
