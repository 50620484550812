export interface DynamicFields {
  companyName: string;
  tierName: string;
  price: string;
  currency: string;
  effectiveDate: string;
  address: string;
}

export const fillTermsContentWithDynamicFields = (
  template: string,
  dynamicFields: DynamicFields
): string => {
  const placeholders = {
    '[PRICE]': dynamicFields.price,
    '[CURRENCY]': dynamicFields.currency,
    '[SUBSCRIPTION_TIER]': dynamicFields.tierName,
    '[COMPANY_NAME]': dynamicFields.companyName,
    '[EFFECTIVE_DATE]': dynamicFields.effectiveDate,
    '[ADDRESS]': dynamicFields.address,
  };

  let result = template;

  for (const [key, value] of Object.entries(placeholders)) {
    const escapedKey = key.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    result = result.replace(new RegExp(escapedKey, 'g'), value);
  }

  return result;
};
