import { type OrderActionModalsAction, type OrderActionModalsState } from '../../types';

export const orderActionsModalsReducer = (
  state: OrderActionModalsState,
  action: OrderActionModalsAction
) => {
  switch (action.type) {
    case 'OPEN_EDIT_CUSTOMER_ADDRESS':
      return {
        ...state,
        data: action.payload,
        customerAddressIsOpen: true,
      };
    case 'CLOSE_EDIT_CUSTOMER_ADDRESS':
      return {
        ...state,
        data: {},
        customerAddressIsOpen: false,
      };
    case 'OPEN_ORDER_DETAILS':
      return {
        ...state,
        data: action.payload,
        orderDetailsIsOpen: true,
      };
    case 'CLOSE_ORDER_DETAILS':
      return {
        ...state,
        data: {},
        orderDetailsIsOpen: false,
      };
    case 'OPEN_EDIT_ORDER':
      return {
        ...state,
        data: action.payload,
        editOrderIsOpen: true,
      };
    case 'OPEN_RECEIVE_ORDER':
      return {
        ...state,
        data: action.payload,
        receiveOrderIsOpen: true,
      };
    case 'OPEN_MARK_AS_RTO':
      return {
        ...state,
        data: action.payload,
        markAsRTOIsOpen: true,
      };
    case 'OPEN_UNDO_RTO':
      return {
        ...state,
        data: action.payload,
        undoRTOIsOpen: true,
      };
    case 'CLOSE_EDIT_ORDER':
      return {
        ...state,
        data: {},
        editOrderIsOpen: false,
      };
    case 'CLOSE_RECEIVE_ORDER':
      return {
        ...state,
        data: {},
        receiveOrderIsOpen: false,
      };
    case 'CLOSE_UNDO_RTO':
      return {
        ...state,
        data: {},
        undoRTOIsOpen: false,
      };
    case 'CLOSE_MARK_AS_RTO':
      return {
        ...state,
        data: {},
        markAsRTOIsOpen: false,
      };
    case 'OPEN_EDIT_PAYMENT_ORDER':
      return {
        ...state,
        data: action.payload,
        editPaymentIsOpen: true,
      };
    case 'CLOSE_EDIT_PAYMENT_ORDER':
      return {
        ...state,
        data: {},
        editPaymentIsOpen: false,
      };
    case 'OPEN_EDIT_CUSTOMER':
      return {
        ...state,
        data: action.payload,
        editCustomerIsOpen: true,
      };
    case 'CLOSE_EDIT_CUSTOMER':
      return {
        ...state,
        data: {},
        editCustomerIsOpen: false,
      };
    case 'OPEN_FORCE_ACTIONS':
      return {
        ...state,
        data: action.payload,
        forceActionsIsOpen: true,
      };
    case 'CLOSE_FORCE_ACTIONS':
      return {
        ...state,
        data: {},
        forceActionsIsOpen: false,
      };
    case 'OPEN_CANCEL_ORDER':
      return {
        ...state,
        data: action.payload,
        cancelOrderIsOpen: true,
      };
    case 'CLOSE_CANCEL_ORDER':
      return {
        ...state,
        data: {},
        cancelOrderIsOpen: false,
      };
    case 'OPEN_DISPATCH_ZONE_ORDER':
      return {
        ...state,
        data: action.payload,
        editDispatchZoneIsOpen: true,
      };
    case 'CLOSE_DISPATCH_ZONE_ORDER':
      return {
        ...state,
        data: {},
        editDispatchZoneIsOpen: false,
      };

    case 'OPEN_ADDRESS_HISTORY':
      return {
        ...state,
        data: action.payload,
        addressHistoryIsOpen: true,
      };
    case 'CLOSE_ADDRESS_HISTORY':
      return {
        ...state,
        data: {},
        addressHistoryIsOpen: false,
      };

    case 'OPEN_FAIL_RETURN':
      return {
        ...state,
        data: action.payload,
        failReturnIsOpen: true,
      };
    case 'CLOSE_FAIL_RETURN':
      return {
        ...state,
        data: {},
        failReturnIsOpen: false,
      };
    case 'OPEN_INTERNATIONAL_SHIPMENT_REQUEST':
      return {
        ...state,
        data: action.payload,
        internationalShipmentIsOpen: true,
      };
    case 'CLOSE_INTERNATIONAL_SHIPMENT_REQUEST':
      return {
        ...state,
        data: {},
        internationalShipmentIsOpen: false,
      };
    case 'OPEN_INTERNATIONAL_SHIPMENT_ERRORS':
      return {
        ...state,
        data: action.payload,
        internationalErrorsIsOpen: true,
      };
    case 'CLOSE_INTERNATIONAL_SHIPMENT_ERRORS':
      return {
        ...state,
        data: {},
        internationalErrorsIsOpen: false,
      };
    case 'OPEN_PROCEDURES':
      return {
        ...state,
        data: action.payload,
        proceduresIsOpen: true,
      };
    case 'CLOSE_PROCEDURES':
      return {
        ...state,
        data: {},
        proceduresIsOpen: false,
      };
    default:
      return state;
  }
};
