import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  HStack,
  IconButton,
  SBEditIcon,
  SBModal,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { type PlanProps, useUpdatePlan } from 'src/components/Particles';

import { useEffect } from 'react';
import { PlanCard } from '../../Components/PlanCard';
import { PlanForm, planSchema } from '../AddPlan';

export function EditPlan(plan: PlanProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateMutate } = useUpdatePlan();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<PlanProps>({
    mode: 'all',
    resolver: yupResolver(planSchema),
  });
  useEffect(() => {
    if (plan) {
      reset({ ...plan });
    }
  }, [plan]);
  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    onClose();
    reset();
  };

  const onConfirm = (data: PlanProps) => {
    void updateMutate({
      variables: { updatePlanInput: { ...data, price: +data.price } },
      onCompleted: (data) => {
        handleCloseModal(data.updateSubscriptionPlan.message);
      },
    });
  };
  const { currency, description, features, name, price, type, isMostPopular } = watch();
  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="delete customer"
        icon={<SBEditIcon width="15px" />}
      />

      <SBModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        header={<Box pb="1vh">Subscription Plan</Box>}
        size="4xl"
        body={
          <Box display="flex" alignItems="start" gap="2">
            <PlanForm register={register} errors={errors} control={control} />
            <Box
              width="346px"
              flexShrink="0"
              height="100%"
              mt={watch('isMostPopular') ? '60px' : '20px'}
            >
              <PlanCard
                currency={currency}
                name={name}
                price={price}
                features={features}
                description={description}
                deleteAble={false}
                type={type}
                editAble={false}
                isMostPopular={isMostPopular}
              />
            </Box>
          </Box>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
