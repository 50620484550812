import { Grid, GridItem, HStack, Text, VStack, Box } from '@swftbox/style-guide';
import { useSubscriptionsPlans } from 'src/components/Particles';
import { PlanCard } from './Components/PlanCard';
import AddPlan from './Modals/AddPlan/AddPlan';
import { useMemo } from 'react';
import { SubscriptionConfig } from './Components/SubscriptionConfig';

export const Subscriptions = () => {
  const { plans } = useSubscriptionsPlans();
  const sortedPlan = useMemo(() => {
    if (!!plans?.length) {
      const clonePlans = [...plans];
      return clonePlans.sort((a, b) => {
        if (a.type === b.type) {
          return a.price - b.price; // Sort by price if types are the same
        }
        return a.type.localeCompare(b.type); // Sort by type
      });
    }
    return [];
  }, [plans]);

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={4}
      // rowGap={1}
      p="10"
      bg="#fff"
      border=" 1px solid #EAECF0"
      borderRadius="12px"
    >
        <GridItem colSpan={12} fontWeight="bold">
          <SubscriptionConfig />
        </GridItem>
      <GridItem colSpan={12} fontWeight="bold">
        <HStack justifyContent="space-between">
          <Text>Subscription Plans</Text>
          <AddPlan />
        </HStack>
      </GridItem>

      <GridItem colSpan={12} overflowX="scroll">
        <VStack
          gap={4}
          alignItems={{ base: 'center', sm: 'baseline' }}
          mt="60px"
          flexDir={{ base: 'column', sm: 'row' }}
          justifyContent={{ base: 'center', sm: 'start' }}
        >
          {sortedPlan?.map((plan) => (
            <Box width="384px" maxW="100%" flexShrink="0">
              <PlanCard {...plan} />
            </Box>
          ))}
        </VStack>
      </GridItem>
    </Grid>
  );
};
