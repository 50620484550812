import { Badge, Box, Button, Flex, HStack, Spacer, Text, Tooltip } from '@swftbox/style-guide';
import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import FilterDropdown from 'src/components/Molecules/FilterDropdown/FilterDropdown';
import {
  type City,
  useCities,
  useFulfillmentOrdersFilters,
  useFulfillmentOrdersFiltersActions,
  useZones,
  type Zones,
} from 'src/components/Particles';
import DateRange from './DateRange';
import SVG from 'react-inlinesvg';
import ResetIcon from 'src/assets/icons/Arrows/refresh.svg';
import { DropProfileType } from './DropProfileType';

const OrdersHeader = () => {
  const [zones, SetZones] = useState<Zones[]>([]);
  const [cities, SetCities] = useState<City[]>([]);
  const { query: getCities } = useCities();
  const { query: getZones } = useZones();
  const dispatch = useFulfillmentOrdersFiltersActions();
  const {
    organizedFilters: { toCity, dispatchZone, dateFilter },
  } = useFulfillmentOrdersFilters();
  useEffect(() => {
    void getCities({
      onCompleted(data) {
        SetCities(
          data.cities.map((city) => ({
            id: city.name,
            name: city.name,
            abbreviation: city.abbreviation,
          }))
        );
      },
    });
    void getZones({
      onCompleted(data) {
        SetZones(
          data.zones.map((zone) => ({
            id: zone.name,
            name: zone.name,
            abbreviation: zone.abbreviation,
          }))
        );
      },
    });
  }, [getCities, getZones]);
  const handleSelectItem = useCallback(
    (e: ChangeEvent<HTMLInputElement> | 'clear', id: any, actionType: any) => {
      if (e === 'clear') {
        dispatch({ type: actionType, payload: { [id]: 'clear' } });
        return;
      }
      dispatch({ type: actionType, payload: { [id]: e.target.value } });
    },
    [dispatch]
  );
  const handleClearAll = () => {
    dispatch({ type: 'CLEAR_ORGANIZING_FILTERS', payload: {} });
  };

  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        <Text fontWeight="semibold" fontSize="text-lg">
          Fulfillment Orders
          <Badge
            p="2px 8px"
            mx="1"
            fontWeight="medium"
            borderRadius="8px"
            fontSize="x-small"
            textTransform="capitalize"
          >
            {/* {totalCount}  */}
            Orders
          </Badge>
        </Text>
      </Box>
      <Spacer />
      <HStack>
        <DropProfileType />
        <HStack spacing="2">
          <FilterDropdown
            list={cities}
            label="Customer City"
            onSelect={handleSelectItem}
            selectedItems={toCity}
            abbreviation
            id="city"
            action="ORDERS_CITIES_CHANGE"
          />
          <FilterDropdown
            list={zones}
            label="Customer zone"
            onSelect={handleSelectItem}
            selectedItems={dispatchZone}
            abbreviation
            id="zone"
            action="ORDERS_ZONES_CHANGE"
          />
          <DateRange />
        </HStack>
        <Box w="40px">
          {(!!toCity || !!dispatchZone || dateFilter?.isSelected) && (
            <Tooltip label="Reset filters" hasArrow>
              <Button
                variant="link"
                color="gray.700"
                onClick={handleClearAll}
                border="1px solid"
                borderColor="#D0D5DD"
                boxShadow="xs"
                height="40px"
              >
                <SVG src={ResetIcon} stroke="currentColor" fill="transparent" width="16px" />
              </Button>
            </Tooltip>
          )}
        </Box>
      </HStack>
    </Flex>
  );
};

export default OrdersHeader;
