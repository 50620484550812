import { FC, ChangeEvent, useEffect } from 'react';
import { HStack, Autocomplete, Grid, GridItem, SBTrashIcon } from '@swftbox/style-guide';
import { FormControl, Input, Text, Box, Button, IconButton, SBAddIcon } from '@swftbox/style-guide';
import { DropProfileCity, useCities } from 'src/components/Particles';

interface Props {
  cities: DropProfileCity[];
  editable?: boolean;
  onChange?: (cities: DropProfileCity[]) => void;
}

const DeleteIcon = () => <span>X</span>;

export const DropProfileCities: FC<Props> = ({ cities, onChange, editable }) => {
  const { data: citiesList, query: getCities } = useCities();
  useEffect(() => {
    getCities();
  }, []);
  const addNewCity = () => {
    onChange &&
      onChange([
        ...cities,
        {
          city: '',
          cutoffTime: '',
          billingSlab: null,
        },
      ]);
  };

  const deleteCity = (index: number) => {
    onChange && onChange(cities.filter((_, i) => i !== index));
  };
  const handleInputName = (inputName: string, event: any) => {
    if (inputName === 'billingSlab') return +event.target.value;
    if (inputName === 'city') return event?.value;
    return event.target.value;
  };
  const handleInputChange = (inputName: string, index: number, e: any) => {
    onChange &&
      onChange(
        cities.map((dropProfileCity, i) => {
          if (i !== index) {
            return dropProfileCity;
          }

          return {
            ...dropProfileCity,
            [inputName]: handleInputName(inputName, e),
          };
        })
      );
  };

  return (
    <Box position="relative">
      <HStack justifyContent="space-between" mb="3">
        <Text fontSize="text-sm" fontWeight="semibold">
          City config
        </Text>
        {editable && (
          <Button size="sm" fontSize="text-sm" onClick={addNewCity}>
            <SBAddIcon width="15px" />
            <Text fontWeight="normal">Add city</Text>
          </Button>
        )}
      </HStack>
      <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4} marginTop={4}>
        <GridItem colSpan={5}>City</GridItem>
        <GridItem colSpan={3}>Cutoff time</GridItem>
        <GridItem colSpan={3}>Billing slab</GridItem>
        <GridItem colSpan={1}></GridItem>
      </Grid>
      {cities &&
        cities.map((city, index) => (
          <Grid
            gridTemplateColumns="repeat(12,1fr)"
            gap="2"
            rowGap={4}
            marginTop={4}
            alignItems="center"
          >
            <GridItem colSpan={5}>
              {' '}
              {!editable ? (
                city.city
              ) : (
                <FormControl>
                  <Autocomplete
                    onChange={(option) => {
                      handleInputChange('city', index, option);
                    }}
                    value={citiesList?.flatMap((option) =>
                      option.abbreviation === city.city
                        ? {
                            label: `${option.name} (${option.abbreviation})`,
                            value: option.abbreviation,
                          }
                        : []
                    )}
                    options={citiesList.map((city) => ({
                      label: `${city.name} (${city.abbreviation})`,
                      value: city.abbreviation,
                    }))}
                    isClearable
                    isSearchable
                  />
                </FormControl>
              )}
            </GridItem>
            <GridItem colSpan={3}>
              {' '}
              {!editable ? (
                city.cutoffTime
              ) : (
                <FormControl>
                  <Input
                    type="time"
                    value={city.cutoffTime}
                    onChange={handleInputChange.bind(null, 'cutoffTime', index)}
                    placeholder="Cutoff time"
                    required
                  />
                </FormControl>
              )}
            </GridItem>
            <GridItem colSpan={3}>
              {' '}
              {!editable ? (
                city.billingSlab
              ) : (
                <FormControl>
                  <Input
                    type="number"
                    value={city.billingSlab || ''}
                    onChange={handleInputChange.bind(null, 'billingSlab', index)}
                    placeholder="Billing slab"
                  />
                </FormControl>
              )}
            </GridItem>
            <GridItem colSpan={1}>
              {editable && (
                <IconButton
                  aria-label="delete-city"
                  icon={<SBTrashIcon width="16px" />}
                  color="red"
                  variant="link"
                  onClick={deleteCity.bind(null, index)}
                />
              )}
            </GridItem>
          </Grid>
        ))}
      {!cities?.length && (
        <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4} marginTop={4}>
          <GridItem colSpan={12}>
            <Text align="center">No city configs added</Text>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

export default DropProfileCities;
