import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  HStack,
  SBAddIcon,
  SBModal,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { type PlanProps, useCreatePlan } from 'src/components/Particles';
import { planSchema } from './Schema';
import { PlanCard } from '../../Components/PlanCard';
import { PlanForm } from './PlanForm';

export default function AddPlan() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createMutate } = useCreatePlan();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<PlanProps>({
    mode: 'all',
    resolver: yupResolver(planSchema),
  });

  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    onClose();
    reset();
  };

  const onConfirm = (data: PlanProps) => {
    void createMutate({
      variables: { createPlanInput: { ...data, price: +data.price } },
      onCompleted: (data) => {
        handleCloseModal(data.createSubscriptionPlan.message);
      },
    });
  };
  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add Subscription Plan
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        header={<Box pb="1vh">Subscription Plan</Box>}
        size="4xl"
        body={
          <Box display="flex" alignItems="start" gap="2">
            <PlanForm register={register} errors={errors} control={control} />
            <Box
              width="346px"
              flexShrink="0"
              height="100%"
              mt={watch('isMostPopular') ? '60px' : '20px'}
            >
              <PlanCard
                currency={watch('currency')}
                name={watch('name')}
                price={watch('price')}
                features={watch('features')}
                description={watch('description')}
                type={watch('type')}
                isMostPopular={watch('isMostPopular')}
                deleteAble={false}
                editAble={false}
              />
            </Box>
          </Box>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
