import { DriverDot } from '@swftbox/style-guide';
import blackDot from './../../../assets/blackMapDot.svg';
import styles from './drivers-tracking.module.scss';
import { useFootprintService } from './services';

const DriversFootPrint = ({ map }: { map: any }) => {
  const footprints = useFootprintService();
  if (!footprints.length) return null;
  return (
    <>
      {footprints.map((footprint) => (
        <DriverDot
          key={footprint?.driverId}
          // @ts-expect-error it's just to ignore dummy ts warning
          map={map}
          driver={footprint}
          icon={blackDot}
          label={{
            text: footprint.driverName, // The label text
            className: styles.mapDriverLabel,
          }}
        />
      ))}
    </>
  );
};

export default DriversFootPrint;
