import {
  Box,
  Button,
  Code,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  VStack,
  toast,
  useClipboard,
} from '@swftbox/style-guide';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  IntegrationType,
  Retailer,
  SaveIntegrationInput,
  useIntegrationsQuery,
  useSaveIntegrationMutation,
  useUpdateDemoSettingMutation,
} from 'src/components/Particles';
import { Config } from 'src/config';
import { SectionRow, SubmitAndCancel } from '../../Shared';

interface Props {
  retailer: Retailer;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
}

export const StorfoxIntegration: FC<Props> = ({ retailer, setAllowToMove }) => {
  const { data: integrations, getIntegrations, loading } = useIntegrationsQuery();
  const { saveIntegration, saving } = useSaveIntegrationMutation();
  const { updateDemoSetting, loading: updating } = useUpdateDemoSettingMutation();

  const storfoxIntegration = useMemo(() => {
    return integrations.find((integration) => integration.type === IntegrationType.STORFOX);
  }, [integrations]);

  const { onCopy, value, hasCopied } = useClipboard('');

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { isDirty: isStorfoxDirty },
  } = useForm<SaveIntegrationInput>({
    mode: 'all',
    defaultValues: {
      id: storfoxIntegration?.id,
      retailerId: retailer.id,
      name: 'Storfox Integration',
      type: IntegrationType.STORFOX,
      serviceApiKey: storfoxIntegration?.serviceApiKey || null,
      warehouseLabel: storfoxIntegration?.warehouseLabel || null,
    },
  });
  const {
    handleSubmit: handleSubmitDemo,
    control: controlDemo,
    reset: resetDemo,
    getValues: demoValues,
    formState: { isDirty: isDemoDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      revealInventoryPage: retailer?.revealInventoryPage,
    },
  });

  useEffect(() => {
    if (!retailer) return;
    getIntegrations(retailer.id);
  }, [retailer, reset]);

  useEffect(() => {
    if (isStorfoxDirty || isDemoDirty) {
      setAllowToMove({ page: '', isAllowed: false });
    } else {
      setAllowToMove({ page: '', isAllowed: true });
    }
  }, [isStorfoxDirty, isDemoDirty]);
  useEffect(() => {
    if (!storfoxIntegration) return;
    reset({
      id: storfoxIntegration.id,
      retailerId: storfoxIntegration.retailerId,
      name: storfoxIntegration.name,
      type: storfoxIntegration.type,
      serviceApiKey: storfoxIntegration.serviceApiKey,
      warehouseLabel: storfoxIntegration.warehouseLabel,
      isActive: !!storfoxIntegration.isActive,
    });
  }, [storfoxIntegration]);

  const onSubmit = handleSubmit(async () => {
    if (isStorfoxDirty) {
      const result = await saveIntegration(getValues());
      reset(getValues());
      toast.success(
        result.data?.createIntegrationConfig?.message ||
          result.data?.updateIntegrationConfig?.message
      );
    }
  });

  const onSubmitDemo = handleSubmitDemo(async () => {
    if (isDemoDirty) {
      const { data: updateResult } = await updateDemoSetting({
        id: retailer.id,
        revealInventoryPage: !!demoValues('revealInventoryPage'),
      });
      resetDemo(demoValues());
      toast.success(updateResult?.updateRetailer.message);
    }
  });
  const onSave = async () => {
    await onSubmit();
    await onSubmitDemo();
  };

  const storfoxWebhooks = useMemo(() => {
    return [
      {
        title: 'Sales Order Webhook',
        url: `${Config.INTEGRATIONS_ENDPOINT}/api/storfox/sale-orders?key=${storfoxIntegration?.apiKey}`,
      },
      {
        title: 'Sales Returns Webhook',
        url: `${Config.INTEGRATIONS_ENDPOINT}/api/storfox/sales-returns?key=${storfoxIntegration?.apiKey}`,
      },
      {
        title: 'Stock Updates Webhook',
        url: `${Config.INTEGRATIONS_ENDPOINT}/api/storfox/stock-updates?key=${storfoxIntegration?.apiKey}`,
      },
    ];
  }, [storfoxIntegration]);

  if (loading) return null;

  return (
    <Box as="form" onSubmit={handleSubmit(onSave)} h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%" gap="4">
        <Box>
          <SectionRow
            title="Inventory Visibility Settings"
            description="  Control the display of inventory information to users, allowing you to show or hide
          details based on specific needs or user roles."
            children={
              <Controller
                name="revealInventoryPage"
                control={controlDemo}
                render={({ field: { onChange, value, ref } }) => (
                  <HStack spacing={5}>
                    <Switch
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Show Inventory Page
                    </Switch>
                  </HStack>
                )}
              />
            }
          />
          <SectionRow
            title=" Storfox Integration Setup"
            description="  provide the necessary credentials. This setup requires your Storfox API key and the
          associated warehouse label to enable seamless connectivity and efficient warehouse
          management."
            children={
              <>
                <Controller
                  name="serviceApiKey"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Storfox API key</FormLabel>
                      <Input
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                        placeholder="Enter Storfox API Key"
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="warehouseLabel"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Storfox Warehouse Label</FormLabel>
                      <Input
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                        placeholder="Enter Storfox Warehouse Label"
                      />
                    </FormControl>
                  )}
                />
              </>
            }
            fullWidthSection={
              <>
                {' '}
                {storfoxWebhooks.map((webhook, index) => (
                  <Box key={`webhook-${index}`} position="relative" width="100%">
                    <FormLabel>{webhook.title}</FormLabel>
                    <HStack>
                      <Code padding={2} width="100%">
                        {webhook.url}
                      </Code>
                      {hasCopied && value === webhook.url && (
                        <Text position="absolute" right={12} bottom={0}>
                          Copied!{' '}
                        </Text>
                      )}
                    </HStack>
                  </Box>
                ))}
              </>
            }
          />
        </Box>

        <SubmitAndCancel
          isHidden={isStorfoxDirty || isDemoDirty}
          reset={() => {
            reset();
            resetDemo();
          }}
          loading={saving || updating}
        />
      </VStack>
    </Box>
  );
};
