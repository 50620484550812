export interface PlanProps {
  id?: string;
  price: number;
  name: string;
  description: string;
  features: string[];
  type: PlanType;
  isMostPopular: boolean;
  currency: string;
}

export enum PlanType {
  FULFILLMENT = 'FULFILLMENT',
  DELIVERY = 'DELIVERY',
}
export interface GetPlanQueryResponse {
  subscriptionPlans: PlanProps[];
}

export interface CreatePlanQueryResponse {
  createSubscriptionPlan: {
    message: string;
    data: PlanProps;
  };
}

export interface CreatePlanQueryRequest {
  createPlanInput: PlanProps;
}

export interface EditPlanQueryResponse {
  updateSubscriptionPlan: {
    message: string;
    data: PlanProps;
  };
}

export interface EditPlanQueryRequest {
  updatePlanInput: PlanProps;
}

export interface DeletePlanQueryResponse {
  removeSubscriptionPlan: {
    message: string;
  };
}

export interface DeletePlanQueryRequest {
  removeSubscriptionPlanId: string;
}

export interface Agreement {
  id: string;
  userId: string;
  isActive: boolean;
  termsVersion: string;
  termsContent: string;
  createdAt: string;
  user: {
    name: string;
    phone: string;
  };
}
