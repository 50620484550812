import { type FC, useState, useEffect } from 'react';
import { Box, Text, Button, SBAddIcon, HStack } from '@swftbox/style-guide';
import { DropProfilesTable } from './DropProfilesTable';
import { DropProfileForm } from './DropProfileForm';
import { type DropProfile, useDropProfilesQuery } from 'src/components/Particles';

interface Props {
  back: () => void;
  retailerId: string;
}

export const DropSettings: FC<Props> = ({ back, retailerId }) => {
  const [showForm, setShowForm] = useState(false);
  const [editDropProfile, setEditDropProfile] = useState<DropProfile>();
  const { getDropProfiles, dropProfiles, loading } = useDropProfilesQuery(retailerId);

  useEffect(() => {
    void getDropProfiles();
  }, [retailerId]);

  const handleEditDropProfile = (dropProfile: DropProfile) => {
    setEditDropProfile(dropProfile);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditDropProfile(undefined);
  };

  return (
    <Box>
      {!showForm ? (
        <>
          <HStack justifyContent="space-between" mb="4">
            <Box />

            <Button size="sm" fontSize="text-sm" onClick={setShowForm.bind(null, true)}>
              <SBAddIcon width="13px" />
              <Text>Create Express Profile</Text>
            </Button>
          </HStack>

          <DropProfilesTable
            dropProfiles={dropProfiles}
            editDropProfile={handleEditDropProfile}
            loading={loading}
            retailerId={retailerId}
          />
        </>
      ) : (
        <DropProfileForm
          retailerId={retailerId}
          dropProfile={editDropProfile}
          onClose={handleCloseForm}
          back={back}
        />
      )}
    </Box>
  );
};
