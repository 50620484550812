import { type TimeSlot } from '../pickupProfiles';

export interface DropProfile {
  id: string;
  name: string;
  key: string;
  description: string;
  cutoffTime: string;
  billingSlab: number;
  profileType: DropProfileType;
  isDefault: boolean;
  isPrimary: boolean;
  isActive: boolean;
  typeDetails: StandardDropProfile | CustomDropProfile | ExpressDropProfile;
  cities: DropProfileCity[];
}

export enum DropProfileType {
  express = 'express',
  standard = 'standard',
  custom = 'custom',
}

export interface DropProfileResponse {
  message: string;
  data: DropProfile;
}

export interface CreateDropProfileInput {
  retailerId: string;
  name: string;
  description: string;
  cutoffTime: string;
  billingSlab: number | null;
  drivingTimeInMins: number;
  maximumEndOffsetInMins: number;
}

export interface UpdateDropProfileInput
  extends Omit<
    CreateDropProfileInput,
    'drivingTimeInMins' | 'maximumEndOffsetInMins' | 'retailerId'
  > {
  id: string;
  customProfile: CustomDropProfile | null;
  expressProfile: ExpressDropProfile | null;
  standardProfile: StandardDropProfile | null;
  cities: DropProfileCity[] | null;
}

export interface ExpressDropProfile {
  drivingTimeInMins: number | null;
  maximumEndOffsetInMins: number | null;
}

export interface StandardDropProfile {
  endOfDayTime: number | null;
}

export interface CustomDropProfile {
  timeSlots?: TimeSlot[] | null;
  leadTimeInHours?: number | null;
  timeSlotsDurationInDays?: number | null;
  preScheduleSlot?: TimeSlot | null;
}

export interface DropProfileCity {
  city: string;
  cutoffTime: string;
  billingSlab: number | null;
}
