import {
  Box,
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SBInput,
  Text,
} from '@swftbox/style-guide';
import ArrowDownIcon from 'src/assets/icons/Arrows/chervonDown.svg';
import XIcon from 'src/assets/icons/General/x.svg';

import { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import ResetIcon from 'src/assets/icons/Arrows/refresh.svg';
import ChipsList from './ChipsDisplay';

interface FilterDropdownProps {
  list: any;
  label: string;
  onSelect: (
    target: ChangeEvent<HTMLInputElement> | 'clear' | string[],
    label: string,
    action: any,
    item?: any
  ) => void;
  selectedItems: any;
  abbreviation?: boolean;
  id: string;
  action: any;
  isSelectedAllEnabled?: boolean;
}

const FilterDropdown = memo(
  ({
    list,
    label,
    onSelect,
    selectedItems,
    abbreviation,
    action,
    id,
    isSelectedAllEnabled,
  }: FilterDropdownProps) => {
    const [filteredList, setFilteredList] = useState(list);
    const [selectedNames, setSelectedName] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const ids = useMemo(() => filteredList.map((item: any) => item.id), [filteredList]);
    useEffect(() => {
      if (searchValue) {
        return setFilteredList(
          list.filter(
            (item: any) =>
              item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              item?.abbreviation?.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      }
      return setFilteredList(list);
    }, [searchValue]);

    useEffect(() => {
      return setFilteredList(list);
    }, [list]);

    useEffect(() => {
      if (!selectedItems?.length) {
        handleSelectItem();
      }
    }, [selectedItems]);

    const handleSelectItem = (name?: string) => {
      if (!name) return setSelectedName([]);
      if (name === 'all') return setSelectedName(filteredList.map((item: any) => item?.name));
      if (selectedNames?.includes(name as string)) {
        return setSelectedName([...selectedNames.filter((selectedName) => selectedName !== name)]);
      }
      return setSelectedName([...selectedNames, name]);
    };

    return (
      <Box position="relative">
        {!!selectedItems?.length && (
          <Button
            variant="link"
            color="#63C3EC"
            bg="#fff"
            position="absolute"
            minW="15px"
            h="15px"
            fontWeight="semibold"
            fontSize="9px"
            borderRadius="50%"
            top="13px"
            left="10px"
            zIndex={10}
            _hover={{ opacity: '0.8' }}
            onClick={() => {
              onSelect('clear', id, action, null);
            }}
          >
            <SVG src={XIcon} width="8px" stroke="currentColor" />
          </Button>
        )}
        <Menu closeOnSelect={false} onClose={() => setSearchValue('')}>
          <MenuButton
            whiteSpace="nowrap"
            transition="all 0.3s"
            w="120px"
            h="40px"
            borderRadius="8px"
            boxShadow="xs"
            fontSize="x-small"
            border="1px solid"
            fontWeight="bold"
            bg={!selectedItems?.length ? '#fff' : '#C9E9F6'}
            color={!selectedItems?.length ? 'gray.700' : '#fff'}
            borderColor={!selectedItems?.length ? '#D0D5DD' : '#63C3EC'}
            _hover={{ opacity: '0.7' }}
          >
            <HStack justifyContent="space-between" px="4">
              <Text>{!selectedItems?.length ? label : <ChipsList labels={selectedNames} />}</Text>
              <SVG src={ArrowDownIcon} fill="currentColor" stroke="transparent" />
            </HStack>
          </MenuButton>
          <MenuList
            borderRadius="8px"
            bg="#fff"
            border="1px solid #EAECF8"
            padding="12px"
            w="200px"
          >
            <SBInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              h="35px"
              mb="3"
              crossOrigin="null"
              placeholder="search..."
            />
            <Box maxH="200px" overflowY="auto">
              {isSelectedAllEnabled && (
                <Box position="sticky" top="0" bg="white" zIndex="1">
                  <Checkbox
                    w="100%"
                    mb="2"
                    onChange={(e) => {
                      if (selectedItems?.length === selectedNames?.length) {
                        onSelect('clear', id, action, null);
                      } else {
                        handleSelectItem('all');
                        onSelect(ids, id, action);
                      }
                    }}
                    // value={item.id}
                    isChecked={selectedItems?.length === selectedNames?.length}
                  >
                    Select All
                  </Checkbox>
                </Box>
              )}
              {/* @ts-expect-error */}
              {filteredList.map((item) => (
                <Checkbox
                  w="100%"
                  mb="2"
                  key={item.id}
                  onChange={(e) => {
                    handleSelectItem(item.name);
                    onSelect(e, id, action, item);
                  }}
                  value={item.id}
                  isChecked={!!selectedItems?.length && selectedItems?.includes(item.id)}
                >
                  {item.name} {abbreviation && `(${item?.abbreviation})`}
                </Checkbox>
              ))}
            </Box>
            {!filteredList.length ? (
              <Text
                fontSize="text-sm"
                color="gray.500"
                fontWeight="semibold"
                w="100%"
                textAlign="center"
              >
                No Result Found
              </Text>
            ) : (
              <Button
                variant="link"
                border="1px solid #D0D5DD"
                color="gray.700"
                fontSize="text-sm"
                mx="auto"
                py="1"
                width="70%"
                boxShadow="xs"
                onClick={() => {
                  onSelect('clear', id, action, null);
                }}
                _hover={{ opacity: '0.7' }}
              >
                <SVG src={ResetIcon} stroke="currentColor" fill="transparent" width="13px" />
                Reset
              </Button>
            )}
          </MenuList>
        </Menu>
      </Box>
    );
  }
);

export default FilterDropdown;
