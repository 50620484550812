import {
  IconWText,
  LabelIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBMenuDotsVerticalIcon,
  Tbody,
  Td,
  Text,
  Tr,
} from '@swftbox/style-guide';
import { memo } from 'react';
import EmptyBox from 'src/assets/emptyBox.svg';

import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { openOrdersShippingLabels } from 'src/components/Pages/Orders/Utils/pdf.helper';
import { useOrderModalActions, type Order } from 'src/components/Particles';
import Shopping_Bag_04 from 'src/assets/icons/eCommerce/shopping-bag-01.svg';
import SVG from 'react-inlinesvg';
import { CancelOrder } from './Modal/CancelOrder';

import OrderItemsModal from './Modal/OrderItem/OrderItems.Modal';
import { PushOrderToDispatch } from './Modal/PushOrderToDispatch';
import { DispatchPlan, DropAddress, FulfillmentPlan, Reference } from './OrderTableColumns';
import CellSelected from './TableCells/CellSelected';
import CellModals from './TableCells/CellModals';
import WorldIcon from 'src/assets/icons/international.svg';
import WorldTrackingIcon from 'src/assets/icons/international-tracking.svg';
import InvoiceIcon from 'src/assets/icons/invoice.svg';
import { Config } from 'src/config';

interface TableBodyProps {
  orders: Order[];
  loading: boolean;
}

const NonMemorizedTableBody = ({ orders, loading }: TableBodyProps) => {
  function goToPrintScreen(order: Order) {
    void openOrdersShippingLabels([order]);
  }

  const dispatch = useOrderModalActions();

  console.log(orders.find((order) => order.swftboxTracking === 'CUPPW40907')?.internationalDetails);
  return (
    <Tbody>
      {orders?.length === 0 && (
        <Tr>
          <Td
            w="100%"
            h="50vh"
            justifyContent="center"
            alignItems="center"
            colSpan={8}
            textAlign="center"
          >
            {loading || loading === undefined ? (
              <div>Loading...</div>
            ) : (
              <EmptyTable icon={EmptyBox} title="No Orders Found" />
            )}
          </Td>
        </Tr>
      )}
      {orders?.map((order: Order) => (
        <CellSelected order={order} key={order.id}>
          <Td>
            <Reference order={order} />
          </Td>
          <Td>
            <DispatchPlan order={order} />
          </Td>
          <Td>
            <FulfillmentPlan order={order} />
          </Td>
          <Td>
            <Text width="150px" textAlign={order.fulfillmentRemarks ? 'inherit' : 'center'}>
              {order.fulfillmentRemarks || '--'}
            </Text>
          </Td>
          <Td>
            <DropAddress order={order} />
          </Td>
          <Td textAlign="end">
            <Menu>
              <MenuButton as="button">
                <SBMenuDotsVerticalIcon width="15px" />
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    goToPrintScreen(order);
                  }}
                >
                  <IconWText
                    text="Shipping label"
                    spacing="20px"
                    Icon={<LabelIcon width="15px" viewBox="0 0 16 16" />}
                  />
                </MenuItem>
                <PushOrderToDispatch order={order} />
                {!Config.PRIMEXPRESS_BUILD && !order.internationalDetails?.isAssigned && (
                  <MenuItem
                    onClick={() => {
                      dispatch({ type: 'OPEN_INTERNATIONAL_SHIPMENT_REQUEST', payload: order });
                    }}
                  >
                    <IconWText
                      text="International shipment"
                      spacing="20px"
                      Icon={<SVG src={WorldIcon} width="15px" height="15px" fill="currentColor" />}
                    />
                  </MenuItem>
                )}
                {!Config.PRIMEXPRESS_BUILD && order.internationalDetails?.isAssigned && (
                  <>
                    {order.internationalDetails?.shippingLabelUrl && (
                      <MenuItem
                        onClick={() => {
                          window.open(order.internationalDetails?.shippingLabelUrl, '_blank');
                        }}
                      >
                        <IconWText
                          text="International shipping label"
                          spacing="20px"
                          Icon={<LabelIcon width="15px" viewBox="0 0 16 16" />}
                        />
                      </MenuItem>
                    )}
                    {order.internationalDetails?.trackingUrl && (
                      <MenuItem
                        onClick={() => {
                          window.open(order.internationalDetails?.trackingUrl, '_blank');
                        }}
                      >
                        <IconWText
                          text="International tracking"
                          spacing="20px"
                          Icon={
                            <SVG
                              src={WorldTrackingIcon}
                              width="16px"
                              height="16px"
                              fill="currentColor "
                            />
                          }
                        />
                      </MenuItem>
                    )}
                    {order.internationalDetails?.commercialInvoiceUrl && (
                      <MenuItem
                        onClick={() => {
                          window.open(order.internationalDetails?.commercialInvoiceUrl, '_blank');
                        }}
                      >
                        <IconWText
                          text="Commercial Invoice"
                          spacing="20px"
                          Icon={
                            <SVG src={InvoiceIcon} width="15px" height="15px" fill="currentColor" />
                          }
                        />
                      </MenuItem>
                    )}
                  </>
                )}
                <OrderItemsModal orderId={order.id} viewItemsComponent={ViewItemsComponent} />
                <CancelOrder order={order} />
              </MenuList>
            </Menu>
          </Td>
        </CellSelected>
      ))}{' '}
      <CellModals />
    </Tbody>
  );
};
const ViewItemsComponent = (
  <MenuItem>
    <IconWText
      text="View Items"
      spacing="20px"
      Icon={<SVG src={Shopping_Bag_04} width="15px" stroke="currentColor" />}
    />
  </MenuItem>
);
export default memo(NonMemorizedTableBody);
