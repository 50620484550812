import { Box, Map } from '@swftbox/style-guide';
import { Config } from 'src/config';
import DriversFootPrint from './DriversFootPrint';
import styles from './drivers-tracking.module.scss';
import { useFootprintService } from './services';

export function DriversTrackingPage() {
  const footprints = useFootprintService();

  return (
    <Box className={styles.mapContainer}>
      <Map
        h="100vh"
        center={Config.DEFAULT_MAP_CENTER}
        zoom={12}
        // @ts-expect-error it's just to ignore dummy ts warning
        Drivers={<DriversFootPrint />}
      ></Map>
    </Box>
  );
}
