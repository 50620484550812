import { gql } from '@apollo/client';

export const GET_RETAILERS = gql`
  query GetRetailers($page: Int, $perPage: Int, $status: [CompanyStatus!], $keyword: String) {
    paginatedRetailers(page: $page, perPage: $perPage, status: $status, keyword: $keyword) {
      data {
        id
        name
        phone
        email
        status
        logo
        isDraftEnabled
        isMultiLocationAllowed
        isManualPickupEnabled
        bagCollection
        orderBalance
        disableCardOnPayment
        customerLocationMatchingStrategy
        categoryIds
        fulfillment {
          id
          name
        }
        billingInfo {
          email
          address
          contactName
          phone
        }
      }
      total
      pageCount
    }
  }
`;

export const GET_SIMPLE_RETAILERS = gql`
  query GetRetailers(
    $page: Int
    $perPage: Int
    $status: [CompanyStatus!]
    $name: String
    $keyword: String
  ) {
    paginatedRetailers(
      page: $page
      perPage: $perPage
      status: $status
      name: $name
      keyword: $keyword
    ) {
      data {
        id
        name
        phone
        email
        status
        logo
        isDraftEnabled
        isMultiLocationAllowed
        bagCollection
        isManualPickupEnabled
        totalPendingPickups {
          locationId
          count
        }
        locations {
          id
          country
          name
          city
          addressLine1
          addressLine2
          addressType
          area
          street
          building
          landMark
          coordinates {
            longitude
            latitude
          }
          entityId
          entityType
          isDefault
          isVerified
          dispatchZone
          dispatchCity
          isInternational
        }
      }
      total
      pageCount
    }
  }
`;

export const GET_TOTAL_RETAILERS = gql`
  query GetRetailers($page: Int, $name: String) {
    paginatedRetailers(page: $page, name: $name) {
      total
    }
  }
`;

export const GET_RETAILER = gql`
  query Retailer($retailerId: UUID!) {
    retailer(id: $retailerId) {
      id
      name
      phone
      email
      emailManifest
      incomingPickupEmail
      emailDailyRecap
      status
      logo
      isDraftEnabled
      isMultiLocationAllowed
      bagCollection
      isManualPickupEnabled
      revealOverviewPage
      revealInventoryPage
      isPaymentLinksEnabled
      isLeaveAtTheDoorEnabled
      fulfillment {
        id
        name
      }
      billingInfo {
        email
        address
        contactName
        phone
      }
      legalInfo {
        id
        legalAddress
        registrationCertificate
        trnCertificate
        legalAddress
      }
      disableCardOnPayment
      customerLocationMatchingStrategy
      categoryIds
      forceExpressReturn
      shippingLabelSize
      retailerConfiguration {
        isDeliveryBoostEnabled
        returnBilling
        rtoBilling
        storageCount
        storageRate
        cardTransactionRate
        cashTransactionRate
        defaultInvoiceType
      }
      features {
        id
        retailerId
        featureKey
        billing
        isEnabled
        isDefault
        feature {
          id
          name
          key
          description
          billing
        }
      }
      procedures {
        id
        type
        description
        images
        videos
      }
    }
  }
`;

export const UPDATE_RETAILER = gql`
  mutation Mutation($updateRetailerInput: UpdateRetailerInput!) {
    updateRetailer(updateRetailerInput: $updateRetailerInput) {
      message
      data {
        id
        name
        phone
        email
        emailManifest
        incomingPickupEmail
        emailDailyRecap
        status
        logo
        isDraftEnabled
        isMultiLocationAllowed
        bagCollection
        isManualPickupEnabled
        fulfillment {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_RETAILER = gql`
  mutation CreateRetailer($createRetailerInput: CreateRetailerInput!) {
    createRetailer(createRetailerInput: $createRetailerInput) {
      message
      data {
        id
        name
        phone
        email
        status
        logo
        isDraftEnabled
        isMultiLocationAllowed
        bagCollection
      }
    }
  }
`;

export const GET_RETAILER_CONFIGS = gql`
  query RetailerConfigurations($retailerId: UUID!) {
    retailerConfigurations(retailerId: $retailerId) {
      retailerId
      returnBilling
      rtoBilling
      storageCount
      storageRate
      cardTransactionRate
      cashTransactionRate
      defaultInvoiceType
    }
  }
`;

export const UPSERT_RETAILER_CONFIGS = gql`
  mutation UpsertConfigurations(
    $upsertRetailerConfigurationInput: UpsertRetailerConfigurationInput!
  ) {
    upsertConfigurations(upsertRetailerConfigurationInput: $upsertRetailerConfigurationInput) {
      message
      data {
        retailerId
        returnBilling
        rtoBilling
        storageCount
        storageRate
        cardTransactionRate
        cashTransactionRate
        defaultInvoiceType
      }
    }
  }
`;

export const UPSERT_RETAILER_FEATURES = gql`
  mutation UpsertRetailerFeatures($upsertRetailerFeaturesInput: UpsertRetailerFeaturesInput!) {
    upsertRetailerFeatures(upsertRetailerFeaturesInput: $upsertRetailerFeaturesInput) {
      message
    }
  }
`;

export const UPDATE_RETAILER_PROCEDURE = gql`
  mutation UpdateProcedure($updateProcedureInput: UpdateProcedureInput!) {
    updateProcedure(updateProcedureInput: $updateProcedureInput) {
      message
      data {
        type
      }
    }
  }
`;

export const CREATE_RETAILER_PROCEDURE = gql`
  mutation CreateProcedure($createProcedureInput: CreateProcedureInput!) {
    createProcedure(createProcedureInput: $createProcedureInput) {
      message
      data {
        type
      }
    }
  }
`;
