import { useLazyQuery } from '@apollo/client';
import { GET_ZONES_QUERY } from '../Queries';
import { Zones } from './types';

interface QueryJobsResponse {
  zones: Zones[];
}

export function useZones() {
  const [query, { data, loading, error }] = useLazyQuery<QueryJobsResponse>(GET_ZONES_QUERY);

  return {
    query,
    data: data?.zones || [],
    loading,
    error,
  };
}
