import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Spinner,
  Badge,
} from '@swftbox/style-guide';
import { FC, useState } from 'react';
import { DropProfile } from 'src/components/Particles';
import { ActionButtons } from './ActionButtons';
import { DropProfileDetails } from './DropProfileDetails';

interface Props {
  dropProfiles: DropProfile[];
  editDropProfile: (dropProfile: DropProfile) => void;
  loading: boolean;
  retailerId: string;
}

const tableHeaders = [
  { title: 'Name', subtitle: '', id: 'name' },
  { title: 'Type', subtitle: '', id: 'type' },
  { title: 'Cuttof time', subtitle: '', id: 'cutoffTime' },
  { title: 'Configured Cities', subtitle: '', id: 'billingSlab' },
  { title: 'Status', subtitle: '', id: 'isDefault' },
  { title: '', subtitle: '', id: 'actions' },
];

export const DropProfilesTable: FC<Props> = ({
  dropProfiles,
  editDropProfile,
  loading,
  retailerId,
}) => {
  const [dropProfile, setDropProfile] = useState<DropProfile | null>(null);

  const onEditDropProfile = (dropProfile: DropProfile) => {
    editDropProfile(dropProfile);
  };

  const onViewDetails = (dropProfile: DropProfile) => {
    setDropProfile(dropProfile);
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h="20vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box>
      {dropProfile ? (
        <DropProfileDetails dropProfile={dropProfile} back={() => setDropProfile(null)} />
      ) : (
        <TableContainer whiteSpace="normal">
          <Table variant="striped">
            <Thead>
              <Tr bg="#fbfbfc" color="neutral.normal">
                {tableHeaders.map((header) => (
                  <Th key={header.id}>
                    <Text>{header.title}</Text>
                    <Text color="neutral.normal">{header.subtitle}</Text>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {dropProfiles?.map((dropProfile: DropProfile, index: number) => {
                return (
                  <Tr key={dropProfile.id} title={dropProfile.description}>
                    <Td>
                      <Text>{dropProfile.name}</Text>
                    </Td>
                    <Td>
                      <Text>{dropProfile.profileType}</Text>
                    </Td>
                    <Td>
                      <Text maxW="100%" fontWeight="semibold">
                        {dropProfile.cutoffTime.replace(new RegExp(':00$'), '')}
                      </Text>
                    </Td>
                    <Td>
                      <Text>{dropProfile.cities.length}</Text>
                    </Td>

                    <Td>
                      {dropProfile.isPrimary && (
                        <Badge
                          p="2px 8px"
                          fontWeight="medium"
                          borderRadius="8px"
                          minWidth={'60%'}
                          textAlign={'center'}
                          fontSize="x-small"
                          variant="solid"
                          colorScheme="blue"
                        >
                          Primary
                        </Badge>
                      )}
                      {!dropProfile.isActive && (
                        <Badge
                          p="2px 8px"
                          fontWeight="medium"
                          borderRadius="8px"
                          minWidth={'60%'}
                          textAlign={'center'}
                          fontSize="x-small"
                          variant="solid"
                          colorScheme="red"
                        >
                          Disabled
                        </Badge>
                      )}
                    </Td>

                    <Td>
                      <ActionButtons
                        dropProfile={dropProfile}
                        onViewDetails={onViewDetails}
                        onEdit={onEditDropProfile}
                        retailerId={retailerId}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
