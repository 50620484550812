import { HStack } from '@swftbox/style-guide';
import { type Order } from 'src/components/Particles';
import { AddressCard } from 'src/components/Organisms';
import { useMemo } from 'react';
interface PickupAddressProps {
  order: Order;
}
export const PickupAddress = ({ order }: PickupAddressProps) => {
  const currentConsumer = useMemo(
    () =>
      order?.isReverse
        ? { consumer: order.customer, location: order.from }
        : { consumer: order.retailer, location: order.from },
    [order]
  );

  return (
    <HStack width={{ lg: '240px', '2xl': '280px' }}>
      <AddressCard
        isVerified={currentConsumer?.location?.isVerified}
        name={
          (order.isReverse ? currentConsumer?.consumer?.name : currentConsumer?.location?.name) ||
          '-'
        }
        phone={currentConsumer?.location.phone ?? currentConsumer?.consumer?.phone}
        address={`${currentConsumer?.location.company ?? ''} ${
          currentConsumer?.location.addressLine1
        } ${currentConsumer?.location.addressLine2 ?? ''} - ${currentConsumer?.location.city} ${
          currentConsumer?.location.stateProvinceCode ?? ''
        } ${currentConsumer?.location.country ?? ''} ${
          currentConsumer?.location.zipPostalCode ?? ''
        }`}
        zone={`${currentConsumer?.location?.city || currentConsumer?.location?.country || '-'}
        ${
          currentConsumer?.location?.dispatchZone
            ? ` - ${currentConsumer?.location?.dispatchZone}`
            : ''
        }`}
        location={currentConsumer?.location}
        editable={!!order?.isReverse}
        showHistory
      />
    </HStack>
  );
};
