import { Autocomplete, Box, Button, HStack, Text, VStack, toast } from '@swftbox/style-guide';
import { format } from 'date-fns/esm';
import { useEffect, useMemo, useState } from 'react';
import { useSubscriptionsPlans } from 'src/components/Particles';
import { useAssignPlanToRetailerQuery } from 'src/components/Particles/resolvers/retailers/AssignPlanToRetailer.service';
import { useCancelPlanRetailerQuery } from 'src/components/Particles/resolvers/retailers/CancelPlanReatiler';
import { useRetailerSubscriptionQuery } from 'src/components/Particles/resolvers/retailers/Subscription.service';
import { ShowTermsOfService } from './ShowTermsOfService';
interface Props {
  retailerId: string;
  retailerName: string;
}
const nullPlans = ['PENDING', 'EXPIRED'];
export const SubscriptionSetting = ({ retailerId, retailerName }: Props) => {
  const { query, data } = useRetailerSubscriptionQuery();
  const [planId, setPlanId] = useState('');

  const { plans } = useSubscriptionsPlans();
  const { handleAssignPlan } = useAssignPlanToRetailerQuery({ retailerId, planId });
  const { handleCancelPlan } = useCancelPlanRetailerQuery({
    retailerId,
    cancelSubscriptionId: data?.retailerSubscription?.id as string,
  });

  const plansOptions = useMemo(
    () => plans?.map((plan) => ({ label: plan.name, value: plan.id })) || [],
    [plans]
  );

  useEffect(() => {
    query({
      variables: {
        retailerSubscribePlanInput: {
          retailerId,
        },
      },
    });
  }, []);

  return (
    <Box h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%">
        <Box>
          {data?.retailerSubscription && !nullPlans.includes(data?.retailerSubscription?.status) ? (
            <Box fontSize="text-sm" fontWeight="semibold">
              <Text>
                Plan Name:{' '}
                <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                  {data?.retailerSubscription?.plan?.name}
                </Text>
              </Text>
              <Text>
                Plan Price:{' '}
                <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                  {data?.retailerSubscription?.plan?.price}{' '}
                  {data?.retailerSubscription?.plan?.currency}
                </Text>
              </Text>
              <Text>
                From:{' '}
                <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                  {format(new Date(data?.retailerSubscription?.startDate), 'dd MMMM yyyy')}
                </Text>
              </Text>
              To:{' '}
              <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                {format(new Date(data?.retailerSubscription?.endDate), 'dd MMMM yyyy')}
              </Text>
              <Text>
                Status:{' '}
                <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                  {data?.retailerSubscription?.status}
                </Text>
              </Text>
              <ShowTermsOfService
                retailerId={retailerId}
                retailerName={retailerName}
                retailerSubscription={data.retailerSubscription}
              />
            </Box>
          ) : (
            <Autocomplete
              label="Subscription Plan"
              placeholder="Assign plan to retailer"
              options={plansOptions || []}
              value={plansOptions?.find((statusOption) => statusOption?.value === planId)}
              onChange={(val) => {
                // @ts-expect-error, it's just a falsy warning
                setPlanId(val?.value);
              }}
            />
          )}
        </Box>
        <HStack
          spacing="3"
          w="100%"
          position="sticky"
          bottom="0"
          // opacity={isDirty ? 1 : 0}
          // pointerEvents={isDirty ? 'auto' : 'none'} // Allow clicks only when visible
          transition="opacity 0.2s, pointer-events 0.2s"
          // disabled={loading}
        >
          {data?.retailerSubscription &&
            !nullPlans.includes(data?.retailerSubscription?.status) && (
              <Button variant="solid" w="100%" onClick={() => handleCancelPlan()} bg="red">
                Cancel Plan
              </Button>
            )}
          {(!data?.retailerSubscription ||
            nullPlans.includes(data?.retailerSubscription?.status)) && (
            <Button
              variant="solid"
              w="100%"
              onClick={() => handleAssignPlan()}
              disabled={!planId && !nullPlans.includes(data?.retailerSubscription?.status)}
            >
              Select Plan
            </Button>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};
