import { Box, HStack, Text, VStack } from '@swftbox/style-guide';
import { type FunctionComponent } from 'react';
import SVG from 'react-inlinesvg';
import GreenCheckIcon from 'src/assets/icons/Arrows/greencheck.svg';
import { type PlanProps } from 'src/components/Particles';
import { DeletePlan } from '../Modals';
import { EditPlan } from '../Modals/EditPlan';
interface PlanCardProps extends PlanProps {
  deleteAble?: boolean;
  editAble?: boolean;
}
export const PlanCard: FunctionComponent<PlanCardProps> = ({
  price,
  name,
  description,
  features,
  isMostPopular,
  currency,
  id,
  type,
  deleteAble = true,
  editAble = true,
}) => {
  if (!name) return null;
  return (
    <VStack
      p="28px"
      border="1px solid #EAECF0"
      boxShadow="xs"
      borderRadius={isMostPopular ? '0px 0px 16px 16px' : '16px'}
      fontSize="text-md"
      position="relative"
      justifyContent="space-between"
      height="100%"
    >
      <Box width="100%">
        <Box fontWeight="medium" color="gray.900" textAlign="start">
          {isMostPopular && (
            <Box
              bg="#000"
              color="#fff"
              position="absolute"
              width="100%"
              left="0"
              top="-40px"
              p="3"
              borderRadius="16px 16px 0px 0px"
              className="recommended-plan"
              textAlign="center"
              z-index="2"
            >
              Most popular plan
            </Box>
          )}
          <HStack justifyContent="space-between" mb="2">
            <Text fontSize="text-lg">{name}</Text>

            <HStack>
              {editAble && (
                <EditPlan
                  description={description}
                  id={id}
                  currency={currency}
                  name={name}
                  features={features}
                  price={price}
                  type={type}
                  isMostPopular={isMostPopular}
                />
              )}
              {deleteAble && <DeletePlan id={id as string} name={name} />}
            </HStack>
          </HStack>

          {price && (
            <Text
              fontSize="display-lg"
              mb="1"
              sx={{
                _after: {
                  content: "'per month'",
                  fontSize: '12px',
                  width: '50px',
                  display: 'inline-block',
                  textAlign: 'left',
                  lineHeight: '12px',
                  marginLeft: '4px',
                  color: 'gray.400',
                  fontWeight: 'medium',
                },
              }}
            >
              {currency} {price}
            </Text>
          )}
          <Text color="gray.600" fontWeight="regular" opacity="0.5" lineHeight="1.1">
            {description}
          </Text>
        </Box>
        <Box mt="6">
          {features.map((feature) => (
            <HStack key={feature} alignItems="start" gap="0.5" mb="2.5" fontSize="text-md">
              {feature !== ' ' && !!feature && (
                <Box>
                  <SVG src={GreenCheckIcon} width="20px" />
                </Box>
              )}
              <Text color="gray.600">{feature} </Text>
            </HStack>
          ))}
        </Box>
      </Box>
    </VStack>
  );
};
