import {
  IconButton,
  SettingIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@swftbox/style-guide';
import { useState } from 'react';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import { PartnerModal } from 'src/components/Pages/Partners/Components/Modals';
import { useConfiguration, type Retailer, fixPrecision } from 'src/components/Particles';
import { PartnerSettingDrawer } from '../Setting';

interface PartnersTableProps {
  retailers: Retailer[];
  page: number;
  perPage: number;
  total: number;
  onPageChange: (page: number) => void;
}

export function PartnersTable(props: PartnersTableProps) {
  const { retailers, page, perPage, total, onPageChange } = props;
  const [selectedRetailerId, setSelectedRetailerId] = useState<string>();
  const { configuration: currency } = useConfiguration('currency');

  function openRetailer(retailerId: string) {
    setSelectedRetailerId(retailerId);
  }

  function closePartnerModal() {
    setSelectedRetailerId(undefined);
  }

  if (!retailers.length) return null;

  return (
    <>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              <Th>Name</Th>
              <Th>PoD balance</Th>
              <Th>Status</Th>
              <Th>Fulfillment Center</Th>
              <Th right="0">
                {' '}
                <SmallPaginate
                  totalOrders={total}
                  page={page ?? 1}
                  perPage={perPage ?? 10}
                  onPageChange={onPageChange}
                />
              </Th>{' '}
            </Tr>
          </Thead>
          <Tbody>
            {retailers.map((retailer) => {
              return (
                <Tr key={retailer.id}>
                  <Td>
                    <Text>{retailer.name}</Text>
                  </Td>

                  <Td>
                    <Text>{`${fixPrecision(retailer.orderBalance)} ${currency?.value}`}</Text>
                  </Td>
                  <Td>
                    <Text>{retailer.status}</Text>
                  </Td>
                  <Td>
                    <Text>{retailer.fulfillment?.name ?? 'N / A'}</Text>
                  </Td>
                  <Td textAlign="end">
                    <IconButton
                      ml="auto"
                      size="lg"
                      variant="link"
                      color="gray.600"
                      _hover={{ opacity: '0.5', color: 'blue' }}
                      aria-label="view location"
                      onClick={() => {
                        openRetailer(retailer.id);
                      }}
                      icon={<SettingIcon width="18px" />}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <PartnerSettingDrawer
        retailerId={selectedRetailerId}
        isOpen={!!selectedRetailerId}
        onClose={closePartnerModal}
      />
      {/* <PartnerModal
        retailerId={selectedRetailerId}
        isOpen={!!selectedRetailerId}
        onClose={closePartnerModal}
      /> */}
    </>
  );
}
