import { HStack } from '@swftbox/style-guide';
import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import FilterDropdown from 'src/components/Molecules/FilterDropdown/FilterDropdown';
import {
  AccountStatus,
  useAllDriversQuery,
  useJobsFilters,
  useJobsFiltersActions,
} from 'src/components/Particles';

interface ListItem {
  name: string;
  id: string;
}

export const OrganizingFilters = () => {
  const dispatch = useJobsFiltersActions();
  const {
    organizedFilters: { driverId },
  } = useJobsFilters();

  const [drivers, SetDrivers] = useState<ListItem[]>([]);
  const { drivers: listOfDriversWithUsers } = useAllDriversQuery({
    isOnline: true,
    userStatus: [AccountStatus.ACTIVE],
  });

  useEffect(() => {
    if (listOfDriversWithUsers.length) {
      SetDrivers(
        listOfDriversWithUsers.map((driver) => ({
          id: driver.id,
          name: driver.user.name,
        }))
      );
    }
  }, [listOfDriversWithUsers]);

  const handleSelectItem = useCallback(
    (e: ChangeEvent<HTMLInputElement> | 'clear', id: any, actionType: any) => {
      if (e === 'clear') {
        dispatch({ type: actionType, payload: { [id]: 'clear' } });
        return;
      }
      dispatch({ type: actionType, payload: { [id]: e.target.value } });
    },
    [dispatch]
  );
  // const handleClearAll = () => {
  //   dispatch({ type: 'CLEAR_ORGANIZING_FILTERS', payload: {} });
  // };

  return (
    <HStack>
      <HStack w="min-content" alignItems="center" justifyContent="end">
        <FilterDropdown
          list={drivers}
          label="Driver"
          onSelect={handleSelectItem}
          selectedItems={driverId}
          id="driver"
          action="JOBS_DRIVER_CHANGE"
        />
      </HStack>
      {/* <Box w="40px">
        {(!!driverId) && (
          <Tooltip label="Reset filters" hasArrow>
            <Button
              variant="link"
              color="gray.700"
              onClick={handleClearAll}
              border="1px solid"
              borderColor="#D0D5DD"
              boxShadow="xs"
              height="40px"
            >
              <SVG src={ResetIcon} stroke="currentColor" fill="transparent" width="16px" />
            </Button>
          </Tooltip>
        )}
      </Box> */}
    </HStack>
  );
};
